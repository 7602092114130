import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Button,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const BankConnectionModal = ({ open, onClose, onConnectBank }) => {
  const handleConnectBank = () => {
    onClose();
    onConnectBank();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: '12px',
          padding: '24px'
        }
      }}
    >
      <Box display="flex" justifyContent="flex-end" style={{position: 'absolute', right: '40px', top: '32px'}}>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent>
        <Typography 
          variant="h5" 
          style={{ 
            color: '#092B4F', 
            fontWeight: '600',
            fontSize: '20px',
            marginBottom: '16px'
          }}
        >
          How we connect with your bank?
        </Typography>
        
        <Box
          width="100%"
          mx="auto"
          borderBottom="1px solid #E0E0E0"
          mb={2}
        />

        <Typography 
          variant="body1"
          style={{ 
            color: '#092B4F',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '150%',
            marginBottom: '24px'
          }}
        >
          Connecting your bank account helps you to make instant rent payments easier and faster than using other methods
        </Typography>

        <Box mb={4}>
          <Box display="flex" alignItems="flex-start" mb={3}>
            <Box
              bgcolor="#EEF2FF"
              borderRadius="50%"
              width={32}
              height={32}
              display="flex"
              alignItems="center"
              justifyContent="center"
              mr={2}
              style={{ flexShrink: 0 }}
            >
              <Typography style={{ color: '#000000', fontWeight: 'bold' }}>1</Typography>
            </Box>
            <Typography variant="body1" style={{ color: '#092B4F', fontWeight: 500, fontSize: '14px', lineHeight: '150%' }}>
              Select the bank account that you wish to connect with Renters.
            </Typography>
          </Box>

          <Box display="flex" alignItems="flex-start" mb={3}>
            <Box
              bgcolor="#EEF2FF"
              borderRadius="50%"
              width={32}
              height={32}
              display="flex"
              alignItems="center"
              justifyContent="center"
              mr={2}
              style={{ flexShrink: 0 }}
            >
              <Typography style={{ color: '#000000', fontWeight: 'bold' }}>2</Typography>
            </Box>
            <Typography variant="body1" style={{ color: '#092B4F', fontWeight: 500, fontSize: '14px', lineHeight: '150%' }}>
              Sign in securely using your bank credentials. Some bank banks require you to enter an OTP or follow instructions on your mobile banking app.
            </Typography>
          </Box>

          <Box display="flex" alignItems="flex-start" mb={4}>
            <Box
              bgcolor="#EEF2FF"
              borderRadius="50%"
              width={32}
              height={32}
              display="flex"
              alignItems="center"
              justifyContent="center"
              mr={2}
              style={{ flexShrink: 0 }}
            >
              <Typography style={{ color: '#000000', fontWeight: 'bold' }}>3</Typography>
            </Box>
            <Typography variant="body1" style={{ color: '#092B4F', fontWeight: 500, fontSize: '14px', lineHeight: '150%' }}>
              Enter the amount that you wish to pay to your property company. You will need to approve and authenticate each payment.
            </Typography>
          </Box>
        </Box>

        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            style={{
              backgroundColor: '#01D4A1',
              color: '#FFF',
              fontWeight: '600',
              borderRadius: '48px',
              padding: '12px 32px',
              fontSize: '16px',
              textTransform: 'none',
              width: '100%',
              maxWidth: '400px'
            }}
            onClick={handleConnectBank}
          >
            Connect Your Bank
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default BankConnectionModal; 