import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Loader } from '..';
import { getAdminCustomerId, getAdminUserPaymentSources, getCustomerIdByUserId, getPaymentDestination, getUserPaymentSourcesByUserId, savePaymentIntent } from '../../services/api';
import config from '../../constants/config';
import { LEAN_PAYMENT_STATUS_INFO } from '../../constants';

const LeanPaymentGateway = ({ 
  amount,
  currency,
  invoiceId,
  tenantDetails,
  navigateFrom,
  setLeanModal,
  propertyShortCode,
  propertyAdminDetails,
  onStepChange, 
  setShowBankTransfer,
  setFetchLeanStatus
}) => {
  const [leanSdkLoaded, setLeanSdkLoaded] = useState(false);
  const [customerId, setCustomerId] = useState('');
  const [customerToken, setCustomerToken] = useState('');
  const [destinationId, setDestinationId] = useState('');
  const [apiAccessToken, setApiAccessToken] = useState('');
  const [readyForPayment, setReadyForPayment] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLeanModalOpen, setIsLeanModalOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [countdown, setCountdown] = useState(10);
  const [status, setStatus] = useState('');
  const [redirectToGateway, setRedirectToGateway] = useState(false);
  const [responseObject, setResponseObject] = useState({});
  const [redirectingTo, setRedirectingTo] = useState('');

  const getStatusIcon = (status) => {
    const iconSize = 64;
    switch (status) {
      case 'SUCCESS':
        return (
          <svg width={iconSize} height={iconSize} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" fill="#52c41a"/>
            <path d="M8 12L11 15L16 9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        );
      case 'CANCELLED':
        return (
          <svg width={iconSize} height={iconSize} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" fill="#ff4d4f"/>
            <path d="M8 8L16 16M8 16L16 8" stroke="white" strokeWidth="2" strokeLinecap="round"/>
          </svg>
        );
      case 'ERROR':
        return (
          <svg width={iconSize} height={iconSize} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" fill="#faad14"/>
            <path d="M12 7V13" stroke="white" strokeWidth="2" strokeLinecap="round"/>
            <circle cx="12" cy="16" r="1" fill="white"/>
          </svg>
        );
      case 'PENDING_WITH_BANK':
        return (
          <svg width={iconSize} height={iconSize} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" fill="#1890ff"/>
            <path d="M12 6V12L15 14" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        );
      default:
        return null;
    }
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    padding: '20px',
    paddingTop: '48px'
  };

  const headingStyle = {
    fontSize: '24px',
    fontWeight: '600',
    marginBottom: '2rem',
    color: '#1a1a1a'
  };

  const initiatePayStyle = {
    fontSize: '18px',
    fontWeight: '600',
    marginBottom: '2rem',
    color: '#1a1a1a'
  };

  const messageStyle = {
    fontSize: '20px',
    marginTop: '1rem',
    marginBottom: '1rem',
    color: '#262626',
    fontWeight: '500',
    padding: '0 12px',
    maxWidth: '800px',
    textAlign: 'center'
  };

  const redirectStyle = {
    fontSize: '16px',
    color: '#595959',
    marginTop: '1.5rem'
  };

  const iconContainerStyle = {
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const isSandbox = config.LEANTECH_BASE_URL && config.LEANTECH_BASE_URL.includes('sandbox') ? 'true' : 'false';

  useEffect(()=>{
    if (tenantDetails?.id) {
        getCustomerIdByUserId({userId: tenantDetails?.id})
        .then((response)=>{
            setCustomerId(response.data?.customerId);
            setCustomerToken(response.data?.customerAccessToken);
            setApiAccessToken(response.data?.apiAccessToken);
        })
        .catch((error)=>console.log("erorrrrr", error));
    } else {
        getAdminCustomerId(propertyAdminDetails?.adminUserId)
        .then((response)=>{
            setCustomerId(response.data?.customerId);
            setCustomerToken(response.data?.customerAccessToken);
            setApiAccessToken(response.data?.apiAccessToken);
        })
        .catch((error)=>console.log("erorrrrr", error));
    }
},[]);


  // Load Lean SDK script dynamically
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.leantech.me/link/sdk/web/latest/Lean.min.js';
    script.async = true;

    script.onload = () => {
      console.log('Lean SDK Loaded');
      setLeanSdkLoaded(true);  // SDK is now loaded and available
      window.scrollTo(0, 0);
    };

    script.onerror = () => {
      console.error('Failed to load Lean SDK.');
    };

    document.body.appendChild(script);

    // Cleanup script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (responseObject) {
      const redirectPage = (["PENDING_WITH_BANK", "FAILED"].includes(responseObject.secondary_status) || ["FAILED", "CANCELLED"].includes(responseObject.status)) ? 'Payment Page' : 'Property Search';

      setRedirectingTo(redirectPage);
    }
  }, [responseObject]);
  useEffect(() => {
    if (redirectToGateway) {
      setReadyForPayment(false);
      setIsLoading(false);
      setIsLeanModalOpen(false);
      setLeanModal(false);
      setShowBankTransfer(false);
      setFetchLeanStatus(true);
      onStepChange?.(3);
    }
  }, [redirectToGateway]);

  function myCallback(responseObject) {
    setResponseObject(responseObject);
    console.log("callback", responseObject)
    const paymentStatus = responseObject.secondary_status === 'PENDING_WITH_BANK' ? 'PENDING_WITH_BANK' : responseObject.status;

    
    setStatus(paymentStatus);
    const statusInfo = LEAN_PAYMENT_STATUS_INFO.find(info => info.label === paymentStatus);
    const message = statusInfo ? statusInfo.value : 'Unknown status';
    setResponseMessage(message);

    if(!readyForPayment 
        && responseObject.status === "SUCCESS" 
        && responseObject.secondary_status !== "PENDING_BENEFICIARY_COOL_OFF"){
      setReadyForPayment(true);
      if (responseObject.entity_id) {
        localStorage.setItem("lean_entity_id", responseObject.entity_id);
      }
      setIsLoading(true);
    } else if(responseObject.secondary_status === "PENDING_BENEFICIARY_COOL_OFF"){
      console.log('Triggered PENDING_BENEFICIARY_COOL_OFF');
      setRedirectToGateway(true);
    }
    else{
        setReadyForPayment(false);
        setIsLoading(false);
        setIsLeanModalOpen(false);
        if (propertyShortCode) {
          const redirectUrl = `/${propertyShortCode}`;
          let timeLeft = 10;
          const timer = setInterval(() => {
            timeLeft -= 1;
            setCountdown(timeLeft);
            if (timeLeft === 0) {
              clearInterval(timer);

              if (responseObject.secondary_status === "PENDING_WITH_BANK" 
                  || responseObject.secondary_status === "FAILED"
                  || responseObject.status === "FAILED"
                  || responseObject.status === "CANCELLED"
              ) {
                setRedirectToGateway(true);
              } else {
                window.location.href = redirectUrl;
              }
            }
          }, 1000);
        }
    }
  }

  function myCallback1(responseObject) {
    setResponseObject(responseObject);
    const paymentStatus = responseObject.secondary_status === 'PENDING_WITH_BANK' ? 'PENDING_WITH_BANK' : responseObject.status;
    setStatus(paymentStatus);
    const statusInfo = LEAN_PAYMENT_STATUS_INFO.find(info => info.label === paymentStatus);
    const message = statusInfo ? statusInfo.value : 'Unknown status';
    setResponseMessage(message);

    if(!readyForPayment && responseObject.status === "SUCCESS"){
      setReadyForPayment(true);
      setIsLoading(true);
    }
    else{
        setReadyForPayment(false);
        setIsLoading(false);
        setIsLeanModalOpen(false)
        if (propertyShortCode) {
          const redirectUrl = `/${propertyShortCode}`;
          let timeLeft = 10;

          const timer = setInterval(() => {
            timeLeft -= 1;
            setCountdown(timeLeft);
            if (timeLeft === 0) {
              clearInterval(timer);

              if (responseObject.secondary_status === "PENDING_WITH_BANK" 
                  || responseObject.secondary_status === "FAILED"
                  || responseObject.status === "FAILED"
                  || responseObject.status === "CANCELLED"
              ) {
                setRedirectToGateway(true);
              } else {
                window.location.href = redirectUrl;
              }
            }
          }, 1000);
        }
    }
  }

  const getPaymentSource = (customerId, paymentSourceId, apiAccessToken) =>{
    console.log("pyyyy====", `${config.LEANTECH_BASE_URL}/customers/v1/${customerId}/payment-sources/${paymentSourceId}` )
    return axios.get(
        `${config.LEANTECH_BASE_URL}/customers/v1/${customerId}/payment-sources/${paymentSourceId}`, 
        {
            headers: {
              Authorization: `Bearer ${apiAccessToken}`,
              'Content-Type': 'application/json',
            },
          }
    )
  }

  const checkCreateBeneficiery = (Lean, paymentSources, customerId, apiAccessToken, destinationId, customerToken, myCallback) =>{
    if (paymentSources.length > 0) {
        const paymentSourceId = paymentSources[0]?.paymentSourceId;
        getPaymentSource(customerId, paymentSourceId, apiAccessToken)
        .then((paymentSourceResponse)=>{
            console.log("paye=====", paymentSourceResponse)
            const paymentDestinationIds = paymentSourceResponse.data.beneficiaries
              .map((beneficiary) => beneficiary.payment_destination_id)
            if (!paymentDestinationIds.includes(destinationId)) {
                Lean.createBeneficiary({
                    app_token: `${config.LEANTECH_APP_ID}`,
                    customer_id: customerId,
                    payment_source_id: paymentSourceId,
                    payment_destination_id: destinationId,
                    access_token: customerToken,
                    sandbox: isSandbox,
                    callback: myCallback,
                    customization: {
                      dialog_mode: 'contained',
                      theme_color: '#01D4A1',
                      button_text_color: '#ffffff',
                      button_border_radius: '4',
                      link_color: '#01D4A1',
                      overlay_color: 'rgba(0, 0, 0, 0.3)',
                    },
                });
            }else{
                setReadyForPayment(true);
            }
        })
        .catch()
    } else {
        if (localStorage.getItem("lean_entity_id")) {
          console.log("Lean already connected");
          return;
        }
        Lean.connect({
          access_token: customerToken,
          customer_id: customerId,
          permissions: ["payments", "identity","accounts","transactions","balance"],
          app_token: `${config.LEANTECH_APP_ID}`,
          payment_destination_id: destinationId,
          sandbox: isSandbox,
          callback: myCallback,
          customization: {
            dialog_mode: 'contained',
            theme_color: '#01D4A1',
            button_text_color: '#ffffff',
            button_border_radius: '4',
            link_color: '#01D4A1',
            overlay_color: 'rgba(0, 0, 0, 0.3)',
          },
        });
    }
  }

  useEffect(()=>{
    // setIsLoading(true);
    setIsLeanModalOpen(true);
getPaymentDestination(invoiceId)
.then((response)=>{setDestinationId(response.data.paymentDestination.paymentDestinationId)})
.catch((error)=>{
    console.log("Error getting payment destination Id", error)
})
  },[])

  useEffect(() => {
    if (leanSdkLoaded) {
      const Lean = window.Lean;  // Lean SDK is loaded
      if (Lean) {
        if(customerId && customerToken && destinationId && apiAccessToken) {
          if(tenantDetails?.id) {
              getUserPaymentSourcesByUserId({userId: tenantDetails?.id})
              .then((response)=>{
                  const paymentSources = response.data;
                  checkCreateBeneficiery(Lean, paymentSources, customerId, apiAccessToken, destinationId, customerToken, myCallback);
              })
              .catch((error)=>{
                  console.log("Error getting payment Sources", error)
              })
          } else {
              getAdminUserPaymentSources(propertyAdminDetails?.adminUserId)
              .then((response)=>{
                  const paymentSources = response.data;
                  checkCreateBeneficiery(Lean, paymentSources, customerId, apiAccessToken, destinationId, customerToken, myCallback);
              })
              .catch((error)=>{
                  console.log("Error getting payment Sources", error)
              })
          }
        }
      } else {
        console.error('Lean SDK is not available.');
      }
    }
  }, [leanSdkLoaded, customerId, customerToken, destinationId, apiAccessToken]);

  useEffect(() => {
    // console.log("apiaccesstoken", apiAccessToken);
    // console.log(readyForPayment, customerToken, currency, amount, destinationId, customerId)
    if (readyForPayment && customerToken && currency && amount && destinationId && customerId) {
      axios
        .post(
          `${config.LEANTECH_BASE_URL}/payments/v1/intents`,
          {
            amount,
            currency,
            payment_destination_id: destinationId,
            customer_id: customerId,
            description: `Payment of amount: ${amount}`,
          },
          {
            headers: {
              Authorization: `Bearer ${apiAccessToken}`,
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          const paymentIntentId = response.data.payment_intent_id;
          savePaymentIntent(invoiceId, paymentIntentId).then((response)=>{}).catch((error)=>{console.log("error====", error.message)})
          const Lean = window.Lean; // Ensure Lean SDK is loaded

          if (Lean) {
            Lean.pay({
              app_token: `${config.LEANTECH_APP_ID}`,
              access_token: customerToken,
              payment_intent_id: paymentIntentId,
              show_balances: false,
              // account_id: "CHOSEN_ACCOUNT_ID",
              sandbox: isSandbox,
              callback: myCallback1,
              customization: {
                dialog_mode: 'contained',
                theme_color: '#01D4A1',
                button_text_color: '#ffffff',
                button_border_radius: '4',
                link_color: '#01D4A1',
                overlay_color: 'rgba(0, 0, 0, 0.3)',
              },
            });
          } else {
            console.error('Lean SDK is not loaded.');
          }
        })
        .catch((error) => console.error('Payment initiation error:', error.message));
    }
  }, [readyForPayment, customerToken, amount, currency, destinationId, customerId]);

  return (
    <div style={containerStyle}>
      {isLoading && <Loader />}
      <script src="https://cdn.leantech.me/link/loader/prod/ae/latest/lean-link-loader.min.js"></script>
      <div id="lean-link"></div>
      { isLeanModalOpen ? (<p style={initiatePayStyle}> Initializing Payment Gateway... </p>): (<h3 style={headingStyle}> Renters Instant Bank Transfers </h3>)}
      {status && (
        <>
          <div style={iconContainerStyle}>
            {getStatusIcon(status)}
          </div>
          {responseMessage && <p style={messageStyle}>{responseMessage}</p>}
        </>
      )}
      {propertyShortCode && responseMessage && (
        <p style={redirectStyle}>
          Redirecting to {redirectingTo} in <strong>{countdown}</strong> seconds
        </p>
      )}
      <div id="lean-link"></div>
    </div>
  );
};

export default LeanPaymentGateway;
