import { Box, Button, Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import Icons from "../constants/icons";
import { Icon } from ".";

export default function PropertyCompanyHeader({ name, logo }) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const handleContactSupport = () => {
    const phoneNumber = "971544551386";
    const message = encodeURIComponent("Hello, I need support with my invoice payment");
    window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`, "_blank");
  };
  return (
    <Box
      display="flex"
      justifyContent={"space-between"}
      alignItems="center"
      px={isMobile ? 2 : 5}
      py={2}
      bgcolor="white"
      borderBottom="1px solid #E0E0E0"
      gap={isMobile ? "10px" : "0"}
    >
      {logo ? (
        <img
          src={logo}
          alt={name}
          style={{
            height: isMobile ? "40px" : "60px",
          }}
        />
      ) : (
        <Typography
          variant={isMobile ? "body1" : "h6"}
          fontWeight="bold"
          textAlign={isMobile ? "center" : "left"}
        >
          {name}
        </Typography>
      )}
      <Button
        onClick={handleContactSupport}
        variant="outlined"
        style={{
          borderColor: "#202020",
          color: "#202020",
          textTransform: "none",
        }}
        size={isMobile ? "small" : "medium"}
      >
        <Box display="flex" alignItems="center" gridColumnGap="8px">
          <Icon path={Icons.chat} />
          Contact Support
        </Box>
      </Button>
    </Box>
  );
}
