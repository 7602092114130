import { Box, Grid, Typography } from "@material-ui/core";
import { Loader } from "../components";
import {
  arrayToCommaString,
  calculateInvoiceDue,
  encryptData,
  formatCurrency,
  getPaymentUrl,
  getProcessingFee,
  roundValue,
  stringifyPayfortObject,
} from "../utils";
import { Link, useParams } from "react-router-dom";
import Icons from "../constants/icons";
import React, { useEffect, useRef, useState } from "react";
import logo from "../assets/logo.png";
import { get, getSettings } from "../services/api";
import moment from "moment";
import "./MakeInvoicePayment.css";
import { PROCESSING_FEE_TYPES } from "../constants";
import TenantPayment from "../components/CustomForm/TenantPayment";
import PropertyCompanyHeader from "../components/PropertyCompanyHeader";
import PropertyCompanyFooter from "../components/PropertyCompanyFooter";

const MakeInvoicePayment = () => {
  const params = useParams();
  const [invoice, setInvoice] = useState([]);
  const [amountDue, setAmountDue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [isAmountLocked, setIsAmountLocked] = useState(false);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    getInvoiceDetails();
  }, [params]);

  const getInvoiceDetails = async () => {
    try {
      setIsLoading(true);
      // Validate invoice payment link, pass token from url query to validateInvoicePaymentLink
      const token = new URLSearchParams(window.location.search).get('token');
      const paymentLinkDetails = await get.validateInvoicePaymentLink(params, token);

      console.log('paymentLinkDetails::', paymentLinkDetails);
      setIsAmountLocked(paymentLinkDetails.data.isAmountLocked);
      setIsValid(paymentLinkDetails.data.isValid);


      const result = await get.getInvoicePaymentDetails(params);
      setInvoice(result.data);
      let pendingAmount = calculateInvoiceDue(
        result.data?.invoiceDetail?.amount,
        result.data?.invoiceDetail?.payments
      );
      setAmountDue(roundValue(pendingAmount));
      console.log("result.data", result.data?.tenancyContactDetails?.status);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleStepChange = (step) => {
    setCurrentStep(step);
  };

  const getStepStatus = (stepNumber) => {
    if (currentStep > stepNumber) return "done";
    if (currentStep === stepNumber) return "active";
    return "pending";
  };

  if (isLoading) {
    return <Loader absolute />;
  }

  return (
    <Box
      bgcolor="#F5F7FA"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
    >
      {!isValid && (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Typography variant="h6" style={{ marginTop: 2, color: "#888" }}>
            Payment link is invalid or expired. Please contact your property manager.
          </Typography>
        </Box>
      )}
      {isValid && (
        <>
          <PropertyCompanyHeader
            name={invoice?.invoiceDetail?.property?.propertyAdmin?.name}
            logo={invoice?.propertyAdminDetails?.logo}
          />

            <div className="progress-container">
              <div className="progress-wrapper">
                <div className={`step ${getStepStatus(1)}`}>
                  <div className="step-circle">1</div>
                  <span className="step-text">Verify Email</span>
                </div>

                <div className={`invoice-step-bar ${currentStep > 1 ? "done" : ""}`}></div>

                <div className={`step ${getStepStatus(2)}`}>
                  <div className="step-circle">2</div>
                  <span className="step-text">Property Details</span>
                </div>

                <div className={`invoice-step-bar ${currentStep > 2 ? "done" : ""}`}></div>

                <div className={`step ${getStepStatus(3)}`}>
                  <div className="step-circle">3</div>
                  <span className="step-text">Payment</span>
                </div>
              </div>
            </div>
            
            <TenantPayment
              tenantDetails={invoice?.invoiceDetail?.tenancyContactDetail?.user}
              propertyDetails={invoice?.invoiceDetail?.property}
              plot={invoice?.invoiceDetail?.propertyLessedUnit?.plotNumber}
              leaseApprovalStatus={
                invoice?.invoiceDetail?.tenancyContactDetail?.status === "approved"
              }
              invoice={[invoice?.invoiceDetail]}
              propertyAdminDetails={invoice?.propertyAdminDetails}
              navigateFrom=""
              onStepChange={handleStepChange}
              isAmountLocked={isAmountLocked}
            />
          <PropertyCompanyFooter />
        </>
      )}
    </Box>
  );
};

export default MakeInvoicePayment;
